import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import Typography from '@mui/material/Typography';
// @mui/icons-material
import Search from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";
import Info from "@mui/icons-material/InfoOutlined";
import ArrowBack from "@mui/icons-material/ArrowBack";
// core components
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import { TextField } from "@mui/material";

import { Dialog, DialogContent, } from "@mui/material";
import Link from '@mui/material/Link';

import { BS_ALE, geraHorarios } from "funcs/MyFuncs";

import {CopyToClipboard} from 'react-copy-to-clipboard';

import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import ReactHtmlParser from 'html-react-parser'; 
import { makeStyles } from "tss-react/mui";

import { Capacitor } from '@capacitor/core';

const useStyles = makeStyles()(styles);
//const  = () => styles;

var fecharPesquisaCont = 0;

export default function Header(props) {

  const {myBackgroundColor, context} = props;

  const { classes } = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [pesquisaOpen, setPesquisaOpen] = React.useState(false);
  const [tipoInfoExibe, setTipoInfoExibe] = React.useState(0);

  const [infoAtual, setInfoAtual] = React.useState("");
  const [infoAtualTitulo, setInfoAtualTitulo] = React.useState("");

  const [infoObrigOpen, setinfoObrigOpen] = React.useState(false);
  const [infoOpen, setInfoOpen] = React.useState(false);
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, brand, fixed, absolute, searchClick, searchFunc, searchValue, defaultIcon, fecharPesquisa } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <Button className={classes.title}>{brand}</Button>;

  // height of the TextField
  const height = 35;

  // magic number which must be set appropriately for height
  const labelOffset = -10;

  const focused = false;

  const mostraIconesDefault = (defaultIcon === false) ? false : true;

  var isOpen = false;
  if (searchValue){
    isOpen = (searchValue.length > 0);
  }

  if (fecharPesquisa > fecharPesquisaCont){
    searchClick(false);
    searchFunc("");
    setPesquisaOpen(false);
    fecharPesquisaCont = fecharPesquisa;
  }

  const renderDialogInfoObrig = (infoObrig, titInfoObrig) => {
    if (infoObrig != ''){
      return (
        <Link 
            variant="body2"
            style={{ fontSize: '16px', margin: 'auto', textDecoration: 'underline', color: '#DAA520', padding: '5px 10px', textAlign: 'center', }}
            onClick={() => {
              setInfoAtual(infoObrig);
              setInfoAtualTitulo(titInfoObrig);
            }}>
            {titInfoObrig}
        </Link>
      );
    }
  }

  const renderAddAtalho = () => {

    if (global.plataforma == 'Web'){
      return (
        <div>
          <div style={{ fontWeight:'bold', fontSize: '16px', margin: 'auto', padding: '20px 10px 10px 10px', textAlign: 'center', }}>
            Quer colocar um atalho <br/> deste site na sua tela inicial?
            <div style={{fontWeight:'400', marginTop: '5px'}}>Isso tornará bem prático o seu acesso!</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Link 
                variant="body2"
                style={{ fontSize: '16px', margin: 'auto', textDecoration: 'underline', color: '#DAA520', padding: '5px 10px', textAlign: 'center', }}
                onClick={() => {
                  setInfoAtual("!ANDROID-MVVM-ANDROID!");
                  setInfoAtualTitulo("Como salvar site na tela inicial - Android");
                }}>
                {"Adicionar no Android"}
            </Link>
            <Link 
                variant="body2"
                style={{ fontSize: '16px', margin: 'auto', textDecoration: 'underline', color: '#DAA520', padding: '5px 10px', textAlign: 'center', }}
                onClick={() => {
                  setInfoAtual("!IOS-AZZA-IOS!");
                  setInfoAtualTitulo("Como salvar site na tela inicial - iPhone");
                }}>
                {"Adicionar no iPhone"}
            </Link>
          </div>
        </div>
      );
    }
  }

  const renderDialogInfoAdic = (infoAdic, titInfoAdic) => {
    if (infoAdic != ''){
      return (
        <Link 
            variant="body2"
            style={{ fontSize: '16px', margin: 'auto', textDecoration: 'underline', color: '#DAA520', padding: '5px 10px', textAlign: 'center', }}
            onClick={() => {
              setInfoAtual(infoAdic);
              setInfoAtualTitulo(titInfoAdic);
            }}>
            {titInfoAdic}
        </Link>
      );
    }
  }

  const renderLinks = (infoAdic, titInfoAdic, infoObrig, titInfoObrig) => {
    return (
      <div style={{display: 'flex', width: '100%', marginBottom: '18px', flexDirection: 'column' }}>
        {renderDialogInfoAdic(infoAdic, titInfoAdic)}
        {renderDialogInfoObrig(infoObrig, titInfoObrig)}
        <Link 
            variant="body2"
            style={{ fontSize: '16px', margin: 'auto', textDecoration: 'underline', color: '#DAA520', padding: '5px 10px', textAlign: 'center', }}
            onClick={() => {
              BS_ALE(context, 'Horários', 
                <div style={{width: '100%'}}>
                    <div style={{width: '215px'}}></div>
                    {geraHorarios(global.horariosFunc)}
                </div>
            )}}>
            {"Horário de funcionamento"}
        </Link>
        <Link 
            variant="body2"
            style={{ fontSize: '16px', margin: 'auto', textDecoration: 'underline', color: '#DAA520', padding: '5px 10px', textAlign: 'center', }}
            onClick={() => {
              setInfoAtual(global.politPriv);
              setInfoAtualTitulo('');
            }}
            >
            {"Política de privacidade"}
        </Link>
        {renderAddAtalho()}
      </div>
    );
  }

  const resolveBack = () => {

    if (infoAtual != ''){
      setInfoAtual('');
      setInfoAtualTitulo('');
    }else{
      setInfoOpen(false);
    }
    
  }

  const renderDialogInfo = () => {

    var msgHTML    = (tipoInfoExibe == 0) ? global.infoAdic       : global.infoObrig;
    var tituloHTML = (tipoInfoExibe == 0) ? global.infoAdicTitulo : global.infoObrigTitulo;

    var infoAdic   = (global.infoAdic)  ? global.infoAdic  : '';
    var infoObrig  = (global.infoObrig) ? global.infoObrig : '';

    if (infoAtual == ""){
      return(
        <Dialog
            open={infoOpen}
            onClose={() => {setInfoOpen(false)}}
            disableBackdropClick 
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ container: classes.root, paper: classes.paper }}
            style={{maxWidth: '720px', margin: '0 auto 0 auto', WebkitOverflowScrolling: 'touch'}}
        >
            <AppBar className={appBarClasses} style={{display: 'flex', flexDirection: 'column', backgroundColor: "#000", marginBottom: '0px'}}>
              <div style={{height: '50px', width: '100%', display: 'flex', position: 'relative'}}>
                <text style={{fontSize: '18px', fontWeight: 'bold', color: '#fff', margin: 'auto auto auto auto'}}>{"Informações"}</text>
                <div onClick={() => {resolveBack()}} style={{fontSize: '24px', fontWeight: '400', color: '#fff', position: 'absolute', top: '0%', height: '100%', width: '50px', left: '0px'}}>
                  <div style={{display: 'flex', width: '100%', height: '100%'}}>
                    <ArrowBack style={{ margin: 'auto', }}/>
                  </div>
                </div>
              </div>                
            </AppBar>
            <DialogContent style={{width: '100%', height: '100%',}}>
                <div style={{display: 'flex', flexDirection: 'column', marginTop: '30px', marginBottom: '0px'}}>
                    <div>
                      <img src={global.siteurl + "rests/" + global.cod_restaurante + "/imgs/rest/path.php?t=restimg"} style={{width: '80%', margin: '10px 10% 10px 10%'}}></img>
                      {renderLinks(infoAdic, global.infoAdicTitulo, infoObrig, global.infoObrigTitulo)}
                      <div style={{fontSize: '12px', color: '#555', width: '100%', textAlign: 'center'}}>V 1.0.28</div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
      );
    }else if (infoAtual == "!IOS-AZZA-IOS!"){
      return(
        <Dialog
            open={infoOpen}
            onClose={() => {setInfoOpen(false)}}
            disableBackdropClick 
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ container: classes.root, paper: classes.paper }}
            style={{maxWidth: '720px', margin: '0 auto 0 auto', WebkitOverflowScrolling: 'touch'}}
        >
          <NotificationContainer style={{bottom: 0}}/>
            <AppBar className={appBarClasses} style={{display: 'flex', flexDirection: 'column', backgroundColor: "#000", marginBottom: '0px'}}>
              <div style={{height: '50px', width: '100%', display: 'flex', position: 'relative'}}>
                <text style={{fontSize: '18px', fontWeight: 'bold', color: '#fff', margin: 'auto auto auto auto'}}>{"Informações"}</text>
                <div onClick={() => {resolveBack()}} style={{fontSize: '24px', fontWeight: '400', color: '#fff', position: 'absolute', top: '0%', height: '100%', width: '50px', left: '0px'}}>
                  <div style={{display: 'flex', width: '100%', height: '100%'}}>
                    <ArrowBack style={{ margin: 'auto', }}/>
                  </div>
                </div>
              </div>                
            </AppBar>
            <DialogContent style={{width: '100%', height: '100%',}}>
                <div style={{display: 'flex', flexDirection: 'column', marginTop: '30px', marginBottom: '0px'}}>
                    
                    <div>
                      <div style={{width: '100%', display: 'flex'}}>
                        <text style={{paddingBottom: '9px', paddingTop: '3px', margin: 'auto', fontSize: '20px', fontWeight: '410', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', color: '#0380fc' }}>{infoAtualTitulo}</text>
                      </div>
                      <div style={{marginTop: '8px'}}>
                        <div style={{marginBottom: '30px'}}>Este WebApp funciona normalmente sem ser instalado, mas se você preferir adicionar um atalho na tela inicial de seu celular para tornar mais fácil o seu acesso, você pode seguir os passos abaixo:</div>
                        <div style={{marginBottom: '20px'}}><b>1</b> - Abra nosso Web App no Safari (este procedimento só funciona no Safari);</div>
                        <div style={{marginBottom: '20px'}}>
                          <b>2</b> - Você pode copiar nossa URL clicando 
                          <CopyToClipboard text={global.siteAtual}
                            onCopy={() => {
                              NotificationManager.success('URL copiada com sucesso !!!', '', 2000);
                            }}>
                            <a > AQUI</a>
                          </CopyToClipboard>
                          ;
                        </div>
                        <div style={{marginBottom: '20px'}}><b>3</b> - Na parte inferior do Safari, clique no ícone que possui uma flecha saindo de uma caixa(compartilhar) para abrir um menu deslizante;</div>
                        <div style={{marginBottom: '20px'}}><b>4</b> - Role o menu, verticalmente, até encontrar a opção 'Adicionar à Área de Trabalho';</div>
                        <div style={{marginBottom: '20px'}}><b>5</b> - Agora você pode escolher o nome que deseja para o site na sua Área de trabalho.</div>
                        <div style={{marginBottom: '20px'}}><b>Pronto! Agora você encontrará um atalho para este site na sua tela inicial.</b></div>
                      </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
      );
    }else if (infoAtual == "!ANDROID-MVVM-ANDROID!"){
      return(
        <Dialog
            open={infoOpen}
            onClose={() => {setInfoOpen(false)}}
            disableBackdropClick 
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ container: classes.root, paper: classes.paper }}
            style={{maxWidth: '720px', margin: '0 auto 0 auto', WebkitOverflowScrolling: 'touch'}}
        >
          <NotificationContainer style={{bottom: 0}}/>
            <AppBar className={appBarClasses} style={{display: 'flex', flexDirection: 'column', backgroundColor: "#000", marginBottom: '0px'}}>
              <div style={{height: '50px', width: '100%', display: 'flex', position: 'relative'}}>
                <text style={{fontSize: '18px', fontWeight: 'bold', color: '#fff', margin: 'auto auto auto auto'}}>{"Informações"}</text>
                <div onClick={() => {resolveBack()}} style={{fontSize: '24px', fontWeight: '400', color: '#fff', position: 'absolute', top: '0%', height: '100%', width: '50px', left: '0px'}}>
                  <div style={{display: 'flex', width: '100%', height: '100%'}}>
                    <ArrowBack style={{ margin: 'auto', }}/>
                  </div>
                </div>
              </div>                
            </AppBar>
            <DialogContent style={{width: '100%', height: '100%',}}>
                <div style={{display: 'flex', flexDirection: 'column', marginTop: '30px', marginBottom: '0px'}}>
                    
                    <div>
                      <div style={{width: '100%', display: 'flex'}}>
                        <text style={{paddingBottom: '9px', paddingTop: '3px', margin: 'auto', fontSize: '20px', fontWeight: '410', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', color: '#0380fc' }}>{infoAtualTitulo}</text>
                      </div>
                      <div style={{marginTop: '8px'}}>
                        <div style={{marginBottom: '30px'}}>Este WebApp funciona normalmente sem ser instalado, mas se você preferir adicionar um atalho na tela inicial de seu celular para tornar mais fácil o seu acesso, você pode seguir os passos abaixo:</div>
                        <div style={{marginBottom: '20px'}}><b>1</b> - Clique no ícone de menu do seu navegador (3 pontos no canto superior direito);</div>
                        <div style={{marginBottom: '20px'}}><b>2</b> - Clique em Instalar Aplicativo (o webapp praticamente não consome memória do seu celular);</div>
                        <div style={{marginBottom: '20px'}}><b>3</b> - Agora você pode escolher o nome que deseja para o site na sua Área de trabalho.</div>
                        <div style={{marginBottom: '20px'}}><b>Pronto! Agora você encontrará um atalho para este site na sua tela inicial.</b></div>
                      </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
      );
    }else{
      return(
        <Dialog
            open={infoOpen}
            onClose={() => {setInfoOpen(false)}}
            disableBackdropClick 
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ container: classes.root, paper: classes.paper }}
            style={{maxWidth: '720px', margin: '0 auto 0 auto', WebkitOverflowScrolling: 'touch'}}
        >
            <AppBar className={appBarClasses} style={{display: 'flex', flexDirection: 'column', backgroundColor: "#000", marginBottom: '0px'}}>
              <div style={{height: '50px', width: '100%', display: 'flex', position: 'relative'}}>
                <text style={{fontSize: '18px', fontWeight: 'bold', color: '#fff', margin: 'auto auto auto auto'}}>{"Informações"}</text>
                <div onClick={() => {resolveBack()}} style={{fontSize: '24px', fontWeight: '400', color: '#fff', position: 'absolute', top: '0%', height: '100%', width: '50px', left: '0px'}}>
                  <div style={{display: 'flex', width: '100%', height: '100%'}}>
                    <ArrowBack style={{ margin: 'auto', }}/>
                  </div>
                </div>
              </div>                
            </AppBar>
            <DialogContent style={{width: '100%', height: '100%',}}>
                <div style={{display: 'flex', flexDirection: 'column', marginTop: '30px', marginBottom: '0px'}}>
                    <div>
                      <div style={{width: '100%', display: 'flex'}}>
                        <text style={{paddingBottom: '9px', paddingTop: '3px', margin: 'auto', fontSize: '20px', fontWeight: '410', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', color: '#0380fc' }}>{infoAtualTitulo}</text>
                      </div>
                      <div> { ReactHtmlParser (infoAtual) } </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
      );
    }

    
  }

  const renderInfoRest = () => {

    return (
      <div onClick={() => {
        setInfoOpen(true);}} 
        style={{fontSize: '24px', fontWeight: '400', color: '#fff', position: 'absolute', top: '0%', height: '100%', width: '50px', right: '0px'}}>
        <div style={{display: 'flex', width: '100%', height: '100%'}}>
          <Info style={{ margin: 'auto' }}/>
        </div>
      </div>
    );
    
  }

  const renderMensagemLojaFechada = (ctx) => {

    if (global.restAberto != '1'){
      return (
        <div style={{width: '100%', backgroundColor: '#777', display: 'flex', position: 'relative'}}>
          <text onClick={() => {
            BS_ALE(ctx, 'Horários', 
              <div style={{width: '100%'}}>
                  <div style={{width: '215px'}}></div>
                  {geraHorarios(global.horariosFunc)}
              </div>
            )
          }} style={{fontSize: '14px', fontWeight: '400', color: 'rgb(251,255,0)', margin: 'auto auto auto auto'}}>{global.proximaAbertura}</text>
        </div>
      );
    }
  }// 

  if (infoOpen){
    return renderDialogInfo();
  }

  return (
    <AppBar className={appBarClasses} style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{height: '35px', width: '100%', backgroundColor: '#777', display: 'flex', position: 'relative'}}>
        <text style={{fontSize: '18px', fontWeight: 'bold', color: '#fff', margin: 'auto auto auto auto'}}>{global.nome_rest}</text>
        {renderInfoRest()}
      </div>
      {renderMensagemLojaFechada(context)}
      <Toolbar className={classes.container} style={{backgroundColor: myBackgroundColor, display: 'flex', flexDirection: 'column'}}>
        <div style={{width: '100%'}}>
          <div style={{flexDirection: 'row', display: 'flex'}}>
            <div className={classes.flex}>
              {leftLinks !== undefined ? (
                <div smDown implementation="css">
                  {leftLinks}
                </div>
              ) : (
                brandComponent
              )}
            </div>
            <Hidden smDown implementation="css">
              {rightLinks}
            </Hidden>
            <div>
              {( ( (!pesquisaOpen) && (!isOpen) ) && (mostraIconesDefault) ) ? (
                <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  searchClick(!pesquisaOpen);
                  setPesquisaOpen(!pesquisaOpen);
                }}
                >
                  <Search />
                </IconButton>
               ) : null}
            </div>
          </div>
          <div></div>
          {( (mostraIconesDefault) && (pesquisaOpen || (isOpen)) ) ? (
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <TextField value={searchValue} onChange={(e) => {
              searchFunc(e.target.value)
              }} label="Pesquisa"  variant="outlined" size="small" type="text" style={{width: '100%', marginBottom: '10px',}} />
              <IconButton
                color="inherit"
                aria-label="open drawer"
                style={{marginBottom: '10px',}} 
                onClick={() => {
                  searchClick(!pesquisaOpen);
                  searchFunc("");
                  setPesquisaOpen(!pesquisaOpen);
                }}
                >
                  <Close />
              </IconButton>

            </div>
          ) : 
          (
             null
          )}
          
        </div>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            {leftLinks}
            {rightLinks}
          </div>
        </Drawer>
      </Hidden>
      {renderDialogInfo()}
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};
