import React, { useState, useEffect } from "react";
import classNames from "classnames";
// @mui/material components
import { withStyles } from "tss-react/mui";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
// @mui/icons-material
import ArrowBack  from "@mui/icons-material/ArrowBack";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Checkbox from  '@mui/material/Checkbox';

import styles from "assets/jss/material-kit-react/views/principalPage.js";

import image from "assets/img/bg7.jpg";
import { Message } from "@mui/icons-material";

import myCookies from 'universal-cookie';

import 'prevent-pull-refresh';

import axios from 'axios';
//import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Dialog, DialogContent, DialogActions, FormControlLabel } from "@mui/material";
import { BS_ALE, myEncodeURI } from "funcs/MyFuncs";

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import  "assets/css/PrincipalPage.css";

import AddProd from "views/PrincipalPage/SelecionaProd_selecionaSabor.jsx";
import Confirmacao from "components/Dialogs/Confirmacao";

import Grid from '@mui/material/Grid';
import AppBar from "@mui/material/AppBar";

import { App as CapacitorApp } from '@capacitor/app';

import { Preferences } from '@capacitor/preferences';

const delayProcessando     = 0;
const tempoMinProcess      = 0;

const corCheckBoxRemove = '#980000';
const corCheckBoxAdiciona = '#cc0000';

const AdicionaCheckbox = withStyles((props) => <Checkbox color="default" {...props} />, {
    root: {
        color: corCheckBoxAdiciona,
      '&$checked': {
        color: corCheckBoxAdiciona,
      },
      [`& label`]: {
        color: corCheckBoxAdiciona,
      },
    },
    checked: {},
  });

class PrincipalPage extends React.Component {

    arrPosItems  = [];    
    arrMenuItems = [];
    cardBody     = null;
    myMenu       = null;
    cabecalho    = null;
    dialogContentAddProd = null;

    ultElementMenu = -1;

    // Embrace React
    anchorRef = React.createRef();

    constructor(props) {
        super(props);
        // Não chame this.setState() aqui!

        console.log("ENTROU - 1 - CARREGANDO");

        let localCart = global.ProductObj.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        let myRestID = localStorage.getItem("@vmx-001/rest-id");
        if (!myRestID) {
            myRestID = "";
        }

        this.state = { 
            pesquisaTxt : global.ProductObj.pesquisaProd,
            listaGruPro: [], usr_id: 12, usr_grures: 6, scrollMarginTop: (global.ProductObj.pesquisaProd.length > 0) ? 170 : 120,
            grupos_prods: [null, null, null, null, null, null, null, null],
            nomeRest: "",
            restID: "" ,
            grupoRest: "",
            promocao: "0",
            itemAtivo: 0,
            posItemClicado: -1,
            eventClicado: -1,
            carrinhoCompra: localCart,
            restAtual: myRestID,

            marginTopStopRefresh: 100,

            alertaAddProd : false,

            alertaNewSabor : false,
            alertaNewSaborPromo : false,

            alertaExitApp: false,

            alertaAlePromo: false,

            produtoPromo : [],

            pickProduto: [],
            pickGruProd: [],

            bannersList : [{
                URL: global.siteurl + "rests/" + 7 + "/imgs/rest/path.php?t=logo",
                color: '#00f',
                callback: () => {
                    BS_ALE(this, "Atenção", "1")
                }
            },{
                URL: global.siteurl + "rests/" + 8 + "/imgs/rest/path.php?t=logo",
                color: '#0f0',
                callback: () => {
                    BS_ALE(this, "Atenção", "2")
                }
            },{
                URL: global.siteurl + "rests/" + 12 + "/imgs/rest/path.php?t=logo",
                color: '#f00',
                callback: () => {
                    BS_ALE(this, "Atenção", "3")
                }
            },],

            imgDetalhes : null,

            politPrivTitulo: 'Política de Privacidade',
            politPriv: '',
            politPrivOpen: '',
            
        };

        this.updateDimensions = this.updateDimensions.bind(this);

    }

    setScrollToTop = () => {

        setTimeout( () => {
            window.scrollTo(0, 0);
        }, 1000)
        
        
    }

    verificaMensageKG(){
        if (!global.mensagemUnidadeShowed){
            var cart = this.state.carrinhoCompra;
            var temProdutoKg = false;
    
            temProdutoKg = cart.some((produto, index) => {
                return produto.sabores.some( (sabor, index) => {
                    var saborAtual = sabor.produto;
                    return (saborAtual.unidade == "KG");
                });
            });
    
            if (temProdutoKg) {
                global.mensagemUnidadeShowed = true;
                BS_ALE(this, 'Atenção', global.txtMensagemUnidade);
            }
        }
    }

    verificaToken = () => {
        let meuToken = localStorage.getItem(global.chaveLocalLogin);
        meuToken = meuToken;

        global.myToken = meuToken;

        if (global.myToken){

            try {
                // here place your login logic     
                        
                var CancelToken = axios.CancelToken;
    
                const {log_senha, log_usu, log_cpf} = this.state;
            
                var parametros = 
                "partoken="    + global.myToken +
                "&pargrures=" + global.cod_grurest +
                "&parres=" + global.cod_restaurante +
                "&parverapp=" + '99';

                let source = CancelToken.source();
                setTimeout(() => {
                    source.cancel();
                }, global.timeOutTimer);
        
                
                axios.post(
                    global.siteurl + 'cliente_info.php', 
                    parametros,
                    {
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                    },
                    withCredentials: true,
                }).then((response) => {
                    var responseData = response.data;

                    if (responseData.cliPermCupom){
                        global.cliPerCupom = responseData.cliPermCupom;
                    }

                    if (responseData.resposta == "2"){
                        this.handleLoad();
                    }else if (responseData.resposta == "0") {
                        global.myToken = "";
                        this.setState({
                            myToken : "",
                            loading: false,
                        }, () => {
                            this.handleLoad();
                        });
                    }else if (responseData.resposta == "77") {
                        global.myToken = "";
                        this.setState({
                            myToken : "",
                            loading: false,
                        }, () => {
                            this.handleLoad();
                        });
                    }else{
                        this.handleLoad();
                    }
                })
                .catch((error) =>{
                    
                    if(axios.isCancel(error)){
        
                        BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                
                    }
                    else{
                
                        BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                
                    }
                });
    
            } catch (err) {
                
            }       
        }else{
            this.setState({
                loading: false,
            }, () => {
                this.handleLoad();
            });
        }
    }

    componentDidMount(){

        var parametros = 'parresid='  + this.state.usr_id + "&parverapp=" + 23;
        this.setState({ 'erroDB' : false });

        this.updateDimensions();
        //window.addEventListener('scroll', this.updateDimensions);
        window.addEventListener('scroll', this.updateDimensions);

        CapacitorApp.addListener('backButton', ({canGoBack}) => {
            if(!canGoBack){
                this.setState({
                    alertaExitApp: true,
                });                
            } else {
                window.history.back();
            }
        });

        try{

            if (global.primeiraVez){
                global.primeiraVez = false;
                this.verificaToken();
            }else{
                this.handleLoad();
            }
            

            var abreTermos = false;

            let versaoTermoAtual = localStorage.getItem(global.cod_restaurante + "versaoTermosVMX");

            versaoTermoAtual = (versaoTermoAtual) ? versaoTermoAtual : 0;

            if (global.mostraTermos){
                if (this.state.carrinhoCompra.length > 0){
                    global.mostraTermos = false;
                    abreTermos          = true;             
                }
            }               

            this.setState({
                versaoTermoAtual : versaoTermoAtual,
                infoObrigOpen: abreTermos,
            });

        }catch (err){
        //BS_ALE(this,  '', 'Não foi possível atualizar as informações, tente novamente mais tarde', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            this.setState({ 'erroDB' : true });
        } 
        //this.verificaAtt();
        this.setScrollToTop();

        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        var   removeSMS = params.get('removesms'); // parametro loja

        if (removeSMS == '1'){
            //params.delete('removesms');
            this.props.navigate({
                pathname: 'login-page',
                search: '',
            });
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.updateDimensions);
    }
    
    updateDimensions() {
        var alturaScroll = document.body.scrollHeight - window.innerHeight;
        var scrolledY    = (window.scrollY - this.state.marginTopStopRefresh);

        var alturaItems  = 0;

        this.arrPosItems.map((item, index) => {
            alturaItems += (item) ? item.clientHeight : 0;
        });

        var proporcao = (alturaItems) / (alturaScroll - this.state.marginTopStopRefresh);

        var tmpPos = 0;
        var itemAtual = 0;

        //const {bottom} = this.anchorRef.current.getBoundingClientRect();

        if (this.anchorRef.current){
            let { bottom } = this.anchorRef.current.getBoundingClientRect();
            BS_ALE(this, 'aaa', Math.floor(bottom))
        }
        

        for (var i = 0; i < this.arrPosItems.length; i++){
            var tmpItem = this.arrPosItems[i];
            tmpPos += (tmpItem) ? tmpItem.clientHeight : 0;

            if ( (scrolledY * proporcao) < ( ( ((this.cabecalho) ? tmpPos + (this.cabecalho.clientHeight) : tmpPos) + this.state.marginTopStopRefresh) - this.state.scrollMarginTop) ){
                break;
            }else{
                itemAtual = (i >= (this.ultElementMenu)) ? this.ultElementMenu : i + 1;
            }
        }        

        if (this.state.posItemClicado == -1){
            if (itemAtual !== this.state.itemAtivo) {
                this.setState({ itemAtivo: itemAtual});

                if (itemAtual > 0){
                    //this.myMenu.scrollTo({left: this.arrMenuItems[itemAtual].offsetLeft - 30, behavior: 'smooth'})
                    this.myMenu.scrollTo({left: this.arrMenuItems[itemAtual].offsetLeft - ((this.myMenu.clientWidth - this.arrMenuItems[itemAtual].clientWidth) / 2) - 30, behavior: 'smooth'})
                }else{
                    this.myMenu.scrollTo({left: 0, behavior: 'smooth'})
                }
            }
        }else if (scrolledY == this.state.posItemClicado){

            if (this.state.eventClicado == -1){
                if (itemAtual !== this.state.itemAtivo) {
                    this.setState({ itemAtivo: itemAtual});
                }
    
                if (itemAtual > 0){
                    this.myMenu.scrollTo({left: this.arrMenuItems[itemAtual].offsetLeft - ((this.myMenu.clientWidth - this.arrMenuItems[itemAtual].clientWidth) / 2) - 30, behavior: 'smooth'})
                }else{
                    this.myMenu.scrollTo({left: 0, behavior: 'smooth'})
                }
                this.setState({ 
                    posItemClicado: -1,
                });
            }else{
                this.myMenu.scrollTo({left: this.arrMenuItems[this.state.eventClicado].offsetLeft - ((this.myMenu.clientWidth - this.arrMenuItems[itemAtual].clientWidth) / 2) - 30, behavior: 'smooth'})
                this.setState({ 
                    itemAtivo: this.state.eventClicado,
                    posItemClicado: -1,
                    eventClicado : -1,
                });
            }
        }else{
            if (itemAtual !== this.state.itemAtivo) {
                this.setState({ itemAtivo: itemAtual});

                if (itemAtual > 0){
                    this.myMenu.scrollTo({left: this.arrMenuItems[itemAtual].offsetLeft - ((this.myMenu.clientWidth - this.arrMenuItems[itemAtual].clientWidth) / 2) - 30, behavior: 'smooth'})
                }else{
                    this.myMenu.scrollTo({left: 0, behavior: 'smooth'})
                }
            }
        }


    }

    atualizaPadraoDB = async (parametros, metodo, tabelaNome, arrLista) => {
        
        try {
    
            var CancelToken = axios.CancelToken;
    
            let source = CancelToken.source();
            setTimeout(() => {
              source.cancel();
            }, 20000);
    
            
            await axios.post(
              global.urlServer + metodo, 
              parametros,
              {
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                }
              }).then((response) => {
                var responseData = response.data;
                if (responseData.resposta == '1') {
                  arrLista = JSON.parse(JSON.stringify(responseData.registros));
                  
                  var arrFinal = [];
    
                  if (arrLista){
                    arrLista.map((prop, key) => {
                      arrFinal.push({
                        key : key, 
                        codInterno : prop.idGrupo, 
                        nome : prop.nome, 
                        precoDelivery : prop.posicao, 
                        descricao : global.urlServer + 'imgs/' + this.state.usr_grures + '/' + prop.cor + '.png',
                        image: global.urlServer + 'imgs/' + this.state.usr_grures + '/' + prop.cor + '.png',
                      });
                    });
                  }
    
                  this.setState({
                    listaGruPro: arrFinal,
                  });
                }else{
                  this.setState({
                    erroDB : true,
                  });
                }
            })
            .catch((error) =>{
              if (error == '785936'){
    
              }else if(axios.isCancel(error)){
    
              }else{
            
    
              }
            });
        } catch (err) {
          
        }    
      } 

    clickProd(item){
        alert('Hello: ' + item.nome);
    }

    scrollToItem = (index) => {
        //alert('Hello: ' + index);
        //this.arrPosItems[index].scrollIntoView({behavior: 'smooth'});

        var offsetNextScroll = 80;

        var alturaScroll = document.body.scrollHeight - window.innerHeight - this.state.scrollMarginTop;
        var scrolledY    = window.scrollY;

        var alturaItems  = 0;

        this.arrPosItems.map((item, index) => {
            alturaItems += (item) ? item.clientHeight : 0;
        });

        var proporcao = (alturaItems) / alturaScroll;

        var posicaoItem  = 0;

        this.arrPosItems.map((item, itemIndex) => {
            if (itemIndex == index){
                posicaoItem = (item) ? ( ((item.offsetTop + this.cardBody.offsetTop) - this.state.scrollMarginTop) + this.state.marginTopStopRefresh ) : 0;
            }
        });

        /*for (var i = 0; i < (index); i++){
            var tmpItem = this.arrPosItems[i];
            posicaoItem += (tmpItem) ? tmpItem.clientHeight : 0;
        }

        posicaoItem -= this.state.scrollMarginTop;
        posicaoItem += this.cabecalho.clientHeight;
        posicaoItem = posicaoItem / proporcao;
        posicaoItem += offsetNextScroll;
        posicaoItem = Math.round(posicaoItem);*/

        window.scrollTo({top: posicaoItem, behavior: 'smooth'});

        this.setState({
            posItemClicado : posicaoItem,
            eventClicado   : index,
        });
        
    }

    renderMenu = (items) => {
        const { classes } = this.props;
        var resultado = null;

        return items.map((item, index) => {

            if (item == null){
                return (
                    <div ref={(ref) => this.arrMenuItems[index] = ref} style={{marginRight: '10px', display: 'inline-block', width: '125px', height: '30px'}} className={'my-loading'}></div>
                ); // my-loading
            }

            var prodArrFinal = [];
            var primeiraCateg = true;
            item.produtos.filter(produto => (this.comparaTexto(produto.nomeProduto, this.state.pesquisaTxt) || this.comparaTexto(produto.descricao, this.state.pesquisaTxt))).map(
                filteredProduto => prodArrFinal.push(filteredProduto)
            );

            if (prodArrFinal.length > 0){
                if (index == this.state.itemAtivo){
                    return (
                        <div ref={(ref) => this.arrMenuItems[index] = ref} style={{marginRight: '30px', display: 'inline-block',}}>
                            <a onClick={() => {this.scrollToItem(index)}} style={{fontSize: '15px', fontWeight: 'bold', color: 'black'}}>{item.nome}</a>
                            <div style={{marginTop: '4px', backgroundColor: '#f00'}} className={classes.separatorMenu}></div>
                        </div>
                    );    
                }else{    
                    return (
                        <div ref={(ref) => this.arrMenuItems[index] = ref} style={{marginRight: '30px', display: 'inline-block',}}>
                            <a onClick={() => {this.scrollToItem(index)}} style={{fontSize: '15px', fontWeight: 'bold', color: 'black'}}>{item.nome}</a>
                        </div>
                    );                      
                }
                
            }else{
                return (
                    <div style={{ display: 'inline-block', }} ref={(ref) => this.arrMenuItems[index] = ref}>

                    </div>
                );
            }
            
        });
    }

    selectProd = (prod, grupoProd) => {

        if (global.pedMesa === true){
            return false;
        }

        if ((prod.promo == '1') & (prod.mistoPromo == '1') & (prod.gruProdsCod != undefined)){

            for (var i = 0; i < this.state.grupos_prods.length; i++){
                if (this.state.grupos_prods[i].codigo == prod.gruProdsCod){
                    
                    if (this.state.grupos_prods[i].misto > 1){
                        global.ProductObj.setQtdeSabores(2, this.state.grupos_prods[i], prod.gruProdsCod, prod.gruProdsNome);    
                        this.setState({
                            alertaNewSaborPromo : true,
                            produtoPromo : prod,

                            pickProduto : [],
                            pickGruProd : [],
                        });
                    }else{
                        

                        global.ProductObj.setQtdeSabores(1, grupoProd, grupoProd.codigo, grupoProd.nome);
                        global.ProductObj.setSabor(prod);
            
                        this.setState({
                            alertaAddProd : true
                        });
                    }

                    break;                
                }
            }

            

        }else{

            if ((grupoProd.misto > "1") & ( (prod.promo != '1') || (prod.mistoPromo == '1') )){
                global.ProductObj.setQtdeSabores(2, grupoProd, prod.gruProdsCod, prod.gruProdsNome);    
                this.setState({
                    alertaNewSabor : true,
                    produtoPromo : [],

                    pickProduto : prod,
                    pickGruProd : grupoProd,
                });
            }else{
                global.ProductObj.setQtdeSabores(1, grupoProd, grupoProd.codigo, grupoProd.nome);
                global.ProductObj.setSabor(prod);
    
                this.setState({
                    alertaAddProd : true
                });
            }   
        }

    }

    selecionaQtdSabor = (qtdeSabores, grupoProd) => {

        global.ProductObj.setQtdeSabores(qtdeSabores, grupoProd, grupoProd.codigo, grupoProd.nome);
        
        this.setState({
            alertaAddProd : true
        });

    }

    renderQtdeSabores = (grupoProd) => {
        const { classes } = this.props;

        return (
            <div>
                <div onClick={(e) => this.selecionaQtdSabor(1, grupoProd)}>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                        <div>
                            <div>
                                <text style={{fontSize:'18px', fontWeight:'bold'}}>{grupoProd.nome} - 1 Sabor</text>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: '10px'}} className={classes.separator}></div>            
                </div>
                <div onClick={(e) => this.selecionaQtdSabor(2, grupoProd)}>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                        <div>
                            <div>
                                <text style={{fontSize:'18px', fontWeight:'bold'}}>{grupoProd.nome} - 2 Sabores</text>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: '10px'}} className={classes.separator}></div>            
                </div>
            </div>
        );
    }

    renderImageGruProd = (cod_grurest, imagem) => {
        if (imagem){
            if (imagem.length > 0){
                return (
                    <img src={global.siteurl + "imgs/" + cod_grurest + "/" + imagem + ".png"} style={{width: '200px', margin: 'auto'}} onError={(e)=>{
                        e.target.onerror = null; 
                        e.target.style.visibility = 'hidden';
                        e.target.style.display = 'none' ;
                    }}></img>
                );
            }
        }
    }

    renderImageProd = (cod_restaurante, imagem) => {
        if (imagem){
            if (imagem.length > 0){
                return (
                    <div onClick={() => { this.setState({ imgDetalhes : global.siteurl + "rests/" + cod_restaurante + "/imgs/prods/" + imagem + ".png" }) }} style={{display: "flex", justifyContent: "flex-end"}}>
                        <img src={global.siteurl + "rests/" + cod_restaurante + "/imgs/prods/" + imagem + ".png"} style={{width: '105px', height: "70px", margin: 'auto 0px'}} onError={(e)=>{
                                e.target.onerror = null; 
                                e.target.style.visibility = 'hidden';
                            }}></img>
                    </div>
                );
            }
        }
    }

    renderDescricao = (descricao) => {

        if ((descricao) && (descricao != '')) {
            return (
                <div>
                    <text style={{display: 'inline-block', fontSize:'13px', lineHeight: '18px'}}>{descricao}</text>
                </div>  
            );
        }

    }

    renderProds = (grupoProd, prodArrFinal) => {
        const { classes } = this.props;

        return prodArrFinal.map((prod, index) => {
            return (
                <div style={{cursor: 'pointer'}}>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                        <div style={{flex: 1}} onClick={() => { this.selectProd(prod, grupoProd) }}>
                            <div>
                                <text style={{fontSize:'16px', fontWeight:'bold'}}>{prod.nomeProduto + ( (global.mostraUnidade) ? ' (' + prod.unidade + ')' : '' )}</text>
                            </div>
                            {this.renderDescricao(prod.descricao)}
                            <div>
                                <text style={{fontSize:'16px', fontWeight:'bold', color: '#777'}}>R$ {prod.preco.replace('.', ',')}</text>
                            </div>    
                        </div>
                        {this.renderImageProd(global.cod_restaurante, prod.imagem)}
                    </div>    
                    <div style={{marginTop: '10px'}} className={classes.separator}></div>            
                </div>
            );
        });
    }

    verificaGrupoProd = ( grupoProd, prodArrFinal ) => {
        //if (grupoProd.misto > 1){
        //    return this.renderQtdeSabores(grupoProd);
        //}else{
        //    return this.renderProds(grupoProd, prodArrFinal);
        //}
        return this.renderProds(grupoProd, prodArrFinal);
    }
    
    renderLista = (items) => {
        const { classes } = this.props;
        var resultado = null;

        return items.map((item, index) => {

            if (item == null){
                return (
                    <div className={"my-loading"}  ref={(ref) => this.arrPosItems[index] = ref} style={{ scrollMarginTop: (this.state.scrollMarginTop + "px"), maxWidth: '100%', marginLeft: '20px', marginRight: '20px', marginBottom: '30px', height: '150px'}}></div>
                ); // my-loading
            }

            var prodArrFinal = [];
            var primeiraCateg = true;
            item.produtos.filter(produto => (this.comparaTexto(produto.nomeProduto, this.state.pesquisaTxt) || this.comparaTexto(produto.descricao, this.state.pesquisaTxt))).map(
                filteredProduto => prodArrFinal.push(filteredProduto)
            );

            if (prodArrFinal.length > 0){
                this.ultElementMenu = index;
                return (
                    <div ref={(ref) => this.arrPosItems[index] = ref} style={{ scrollMarginTop: (this.state.scrollMarginTop + "px"), maxWidth: '100%', marginLeft: '20px', marginRight: '20px', marginBottom: '30px'}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                            {this.renderImageGruProd(global.cod_grurest, item.imagem)}
                            <text style={{margin: '7px auto', fontSize: '22px', color: '#a30000', fontWeight: 'bold'}} >{item.nome}</text>
                            <div style={{height: '2px'}} className={classes.separator}></div>
                        </div>                
                        <div>
                            {this.verificaGrupoProd(item, prodArrFinal)}
                        </div>
                    </div>
                );
            }else{
                return (
                    <div ref={(ref) => this.arrPosItems[index] = ref} >
                        
                    </div>
                );
            }

            /*return (
                <ListItem style={{scrollMarginTop: this.state.scrollMarginTop, }} key={index} ref={(ref) => this.arrPosItems.push(ref)} onClick={() => this.clickProd(item)} alignItems="flex-start">
                    <ListItemAvatar> 
                        <Avatar variant="square" imgProps={{width: '100%', height: 'unset'}} src={item.image} classes={{ img: classes.myAvatarImg }} className={classes.myImgProds} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={item.nome}
                        secondary={
                            <React.Fragment>
                                {this.renderProds(item)}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            );*/
        });
    }

    pesquisaLista = (value) => {
        global.ProductObj.pesquisaProd = value;
        this.setState({pesquisaTxt: value}, () => {
            this.updateDimensions();
        });
    }

    abrePesquisa = (pesquisaAberta) => {
        if (pesquisaAberta){
            this.setState({
                scrollMarginTop: 170
            });
        }else{
            this.setState({
                scrollMarginTop: 120
            });
        }        
    }

    comparaTexto(val1, val2){

        var arrPalavras = val2.split(' ');
    
        var resultado = true;
        arrPalavras.map((palavra, index) => {
          if (!this.trataTexto(val1).includes(this.trataTexto(palavra))){
            resultado = false;
          }
        });
    
        return resultado;
    }

    trataTexto(val){
        return (
            val.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        );
    }

    handleLoad() {
        var menuItems = [];
    
        try {
            
          var CancelToken = axios.CancelToken;
    
          let source = CancelToken.source();
          setTimeout(() => {
            source.cancel();
          }, 20000);

          var parametros = 'parresid=' + global.cod_restaurante + '&pargrures=' + global.cod_grurest + '&parmesa=' + (global.pedMesa ? "1" : "0");
                   
          console.log("ENTROU - 2 - CARREGANDO");
          axios.post(
            global.siteurl + 'produtos_lista.php', 
            parametros,
            {
                cancelToken: source.token,
                headers: {
                   'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                },
                withCredentials: true
            }).then((response) => {
                
              var responseData = response.data;

              console.log("ENTROU - 3 - CARREGANDO");
              // 
              if (responseData.resposta == '1') {
                var arrLista = JSON.parse(JSON.stringify(responseData.registros));
                var arrFinal = [];

                console.log(`ERR - TRACK - restaberto - 4 - ${global.restAberto}`);
                global.restAberto = (responseData.restaurante.aberto) ? responseData.restaurante.aberto : "0";
                console.log(`ERR - TRACK - restaberto - 4.1 - ${global.restAberto}`);
    
                if (responseData.promocao == '1'){
    
                  var arrProdPromo = [];
    
                  for (var index = 0; index < responseData.registros.length; index++){
                    for (var i = 0; i < responseData.registros[index].produtos.length; i++){
                        if ((responseData.registros[index].produtos[i].promo == "1") && ( this.comparaTexto(responseData.registros[index].produtos[i].nomeProduto, this.state.pesquisaTxt) || this.comparaTexto(responseData.registros[index].produtos[i].descricao, this.state.pesquisaTxt ) ) ){
                            arrProdPromo.push( responseData.registros[index].produtos[i] );

                            arrProdPromo[arrProdPromo.length - 1].gruProdsAdics = responseData.registros[index].grupoAdics;
                            arrProdPromo[arrProdPromo.length - 1].gruProdsCod     = responseData.registros[index].idGrupo;
                            arrProdPromo[arrProdPromo.length - 1].gruProdsNome    = responseData.registros[index].nome;

                            arrProdPromo[arrProdPromo.length - 1].grupoPontosBonusGer = responseData.registros[index].fide_beneficios;
                            arrProdPromo[arrProdPromo.length - 1].grupoPontosBonusRes = responseData.registros[index].fide_resgate;
                        }
                    };
                  }
    
                  arrFinal.push({
                    nome : "Promoções",
                    imagem : "",
                    info : "",
                    produtos : arrProdPromo,
                  });
                }
    
                arrLista.map((prop, key) => {
    
                  arrFinal.push({
                    nome : prop.nome,
                    misto : prop.misto,
                    imagem : prop.cor,
                    info : prop.info,
                    codigo: prop.idGrupo,
                    produtos : prop.produtos,
                    grupoAdics : prop.grupoAdics,
                    fide_beneficios : prop.fide_beneficios,
                    fide_resgate : prop.fide_resgate,
                  });
                  
                });
                
                document.title = "Web App - " + responseData.restaurante.nome;
                
                this.setState({
                  grupos_prods: arrFinal,
                  nomeRest: responseData.restaurante.nome,
                  restID: responseData.restaurante.cod_rest,
                  grupoRest: responseData.restaurante.grupo_rest,
                  promocao: responseData.promocao,
                  cli_percupom: responseData.verifica_rest.cli_percupom,
                  cli_perfide: responseData.verifica_rest.cli_perfide,
                  cli_peralepro: responseData.verifica_rest.cli_peralepro
                }, () => { 
                    const cookies = new myCookies();

                    var enviarCookie = (cookies.get(global.cod_restaurante + '_contaAccess') != 'TALION');

                    if (enviarCookie){

                        var now = new Date();
                        var time = now.getTime();
                        var maxAge     = 60*60*2;
                        var expireTime = time + maxAge;

                        var expires = (new Date(expireTime));

                        cookies.set(global.cod_restaurante + '_contaAccess', 'TALION', { path: '/', expires: expires, maxAge: maxAge });
                        
                        var CancelToken = axios.CancelToken;

                        const {log_senha, log_usu} = this.state;
                    
                        var parametros = 
                        "parres="    + global.cod_restaurante;

                        let source = CancelToken.source();
                        setTimeout(() => {
                            source.cancel();                            
                        }, global.timeOutTimer);
                        
                        axios.post(
                            global.siteurl + 'count_access.php', 
                            parametros,
                            {
                            cancelToken: source.token,
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                            },
                            withCredentials: true,
                        }).then((response) => {
                            
                        })
                        .catch((error) =>{
                            
                            if(axios.isCancel(error)){
                
                                //BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 029');
                        
                            }
                            else{
                                
                            }
                        });
                        

                    }
                    
                    if ( (((global.cupomAtivado == 1 && this.state.cli_percupom == '0') || (global.programaFidelidade == 1 && this.state.cli_perfide == '0'))) && (this.state.cli_peralepro == '1') ){

                        this.verificaAlertaPromo();

                    }
                    
                });
              }else{
                  
              }
          })
          .catch((error) =>{

            if(axios.isCancel(error)){
            }
            else{
            }
          });
        } catch (err) {
          
        }  
    
    
        
    }

    verificaAlertaPromo = async () => {

        const cookies = new myCookies();

        var mostrarAlerta = (cookies.get(global.cod_restaurante + '_showPromoAle') != 'DESPIA');

        if (global.plataforma == 'IOS'){
            
            const { value } = await Preferences.get({ key: `${global.cod_restaurante}_showPromoAle` });
            mostrarAlerta = (value != 'DESPIA');
            
        }

        if (mostrarAlerta){
            var now = new Date();
            var time = now.getTime();
            var maxAge     = 60*60*24*3;
            var expireTime = time + maxAge;

            var expires = (new Date(expireTime));

            this.setState({
                alertaAlePromo: true,
            }, async () => {
                cookies.set(global.cod_restaurante + '_showPromoAle', 'DESPIA', { path: '/', expires: expires, maxAge: maxAge });

                if (global.plataforma == 'IOS'){
                    await Preferences.set({
                        key: `${global.cod_restaurante}_showPromoAle`,
                        value: 'DESPIA',
                    });
                }

            });
        }
    }

    handleChangeRegulamentoAceito = (e) => {
        this.setState({
            regulamentoAceita : !(this.state.regulamentoAceita)
        });
    }

    handleCloseAddProd = () => {

        let localCart = global.ProductObj.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        this.setState({
            alertaAddProd : false,
            carrinhoCompra: localCart
        })
    }

    handleDialogScroll = () => {
        var teste = this.dialogContentAddProd.scrollTop;

        this.setState({
            dialogScrollPos : teste
        })
    }

    renderDialogAddProd = () => {

        const { classes } = this.props;

        const appBarClasses = classNames({
            [classes.appBar]: true,
            [classes.color]: '#000',
            [classes.absolute]: 'absolute',
            [classes.fixed]: 'fixed'
          });

        return (

            <Dialog
                open={this.state.alertaAddProd}
                onClose={this.handleCloseAddProd}
                disableBackdropClick 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ container: classes.rootNoPadding, paper: classes.paperFullSize }}
                style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
            >
                <DialogContent onScroll={() => this.handleDialogScroll()} ref={(ref) => {this.dialogContentAddProd = ref}} style={{width: '100%', height: '100%', padding: '0px'}}>
                    <AddProd 
                        vmxale={this.props.vmxale}
                        scrollFunction={() => {
                            this.dialogContentAddProd.scrollTo({top: 0, behavior: 'auto'});
                        }} 
                        posicaoScroll={this.state.dialogScrollPos}
                        closeFunc={() => {
                            this.handleCloseAddProd()
                        }}
                    />
                </DialogContent>
            </Dialog>

        );
    }

    bloqPromoAle = () => {

        try {
            // here place your signup logic     
            
            var CancelToken = axios.CancelToken;

            let meuToken = localStorage.getItem(global.chaveLocalLogin);
            meuToken = meuToken;

            global.myToken = meuToken;
      
            var parametros = 
            "partoken="  + global.myToken +
            "&pargrures=" + global.cod_grurest +
            "&parverapp=" + '99';
    
            let source = CancelToken.source();
            setTimeout(() => {
                source.cancel();
            }, global.timeOutTimer);
    
            
            axios.post(
                global.siteurl + 'cliente_bloq_promoale.php', 
                parametros,
                {
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                },
                withCredentials: true,
            }).then((response) => {
                var responseData = response.data;
                if (responseData.resposta == '1') {
                    
                    this.setState({
                        alertaAlePromo: false,
                    }, () => {
                        this.handleLoad();
                    });
                    
                }else{
                    BS_ALE(this,  'Atenção', responseData.msg, [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                }
            })
            .catch((error) =>{
                
                if(axios.isCancel(error)){
    
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
                else{
            
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
            });
            
        } catch (err) {
            BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);            
        }
    }

    renderDialogPromoAle = () => {

        const { classes } = this.props;

        const appBarClasses = classNames({
            [classes.appBar]: true,
            [classes.color]: '#000',
            [classes.absolute]: 'absolute',
            [classes.fixed]: 'fixed'
          });

        var titulo = "PROMOÇÕES E CUPONS DE DESCONTO";
        var texto  = "Agora você pode participar do nosso programa de promoções, clique em PARTICIPAR e comece a economizar.";

        var random = Math.floor(Math.random() * 10);
        
        if ((random > 3 || global.cupomAtivado == 0 || this.state.cli_percupom == '1') && (global.programaFidelidade == 1 && this.state.cli_perfide == '0')){
            var titulo = "PROGRAMA DE FIDELIDADE";
            var texto  = "Agora você pode participar do nosso programa de fidelidade, clique em PARTICIPAR e comece a economizar.";

            //texto =  <img width="95%" src="https://i0.wp.com/blog.mascotaysalud.com/wp-content/uploads/2019/08/bulldog-frances-1.jpg?w=865&ssl=1" alt="Flowers in Chania"></img>
        }

        return (

            <Dialog
                open={this.state.alertaAlePromo}
                onClose={this.handleCloseAlePromo}
                disableBackdropClick 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ container: classes.root, paper: classes.dialogProAle }}
                style={{maxWidth: '620px', margin: '0 auto 0 auto',}}
            >
                <DialogContent style={{padding: '5px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: '0px', marginBottom: '0px'}}>
                            <div style={{margin: '10px', textAlign: 'center', color: '#03a1fc', fontWeight: '500'}}>
                                {titulo}
                            </div>
                            <div style={{margin: '10px', textAlign: 'center', fontWeight: '500', color: '#000'}}>
                                {texto}
                            </div>
                        </div>
                </DialogContent>
                <DialogActions style={{display: 'flex', flexDirection: 'column'}}>

                    <div style={{width: '100%', display: 'flex'}}>
                        <Button color="primary" style={{backgroundColor: '#03a1fc', margin: 'auto', height: '35px !important', maxWidth: '100%', color: '#fff'}}
                            onClick={() => {
                                this.props.navigate({
                                    pathname: 'login-page',
                                });
                            }}>
                            Quero Participar
                        </Button>
                    </div>

                    <div style={{width: '100%', display: 'flex', marginTop: '20px', marginBottom: '9px'}}>
                        <a
                            onClick={() => {
                                this.setState({
                                    alertaAlePromo: false,
                                })
                            }}
                            style={{color: '#888', fontSize: '14px', fontWeight: '550', textDecoration: 'underline', textAlign: 'center', width: '100%', margin: 'auto'}}
                        >
                            Lembrar-me depois
                        </a>
                    </div>
                    
                </DialogActions>
            </Dialog>

        );
    }

    changeCorSlider = (slider, cores) => {

        //BS_ALE(this, 'Atenção', slider.currentIndex);

        /*document.documentElement.style.setProperty('--control-bullet-active-color', cor);
        document.documentElement.style.setProperty('--control-bullet-color',        cor, '!important');
        document.documentElement.style.setProperty('--organic-arrow-color',         cor, '!important');*/

        //var stylesheet = document.styleSheets[0]
        //stylesheet.insertRule(".awssld { --control-bullet-active-color: #f0f; --control-bullet-color: #f0f; --organic-arrow-color: #f0f;}", 0);

        var cor = this.state.bannersList[slider.currentIndex].color;

        var cols = document.getElementsByClassName('awssld');
        for(var i = 0; i < cols.length; i++) {
            cols[i].style.setProperty("--control-bullet-active-color", cor);
            cols[i].style.setProperty("--control-bullet-color", cor);
            cols[i].style.setProperty("--organic-arrow-color", cor);
        }
    }

    renderDialogImage = () => {

        const { classes } = this.props;

        const appBarClasses = classNames({
            [classes.appBar]: true,
            [classes.color]: '#000',
            [classes.absolute]: 'absolute',
            [classes.fixed]: 'fixed'
          });

        var versaoTermoAtual = this.state.versaoTermoAtual

        return(
          <Dialog
              open={ (this.state.imgDetalhes != null) }
              onClose={() => {
                this.setState({
                    infoObrigOpen: false
                });
              }}
              disableBackdropClick 
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{ container: classes.root, paper: classes.paperImg }}
              style={{maxWidth: '720px', margin: '0 auto 0 auto',}}
          >
              <div className={appBarClasses} style={{display: 'flex', flexDirection: 'column', backgroundColor: "#000", marginBottom: '0px'}}>
                <div style={{height: '50px', width: '100%', display: 'flex', position: 'relative'}}>
                    <text style={{fontSize: '18px', fontWeight: 'bold', color: '#fff', margin: 'auto auto auto auto'}}></text>
                    <div onClick={() => { this.setState({ imgDetalhes : null }) }} style={{fontSize: '24px', fontWeight: '400', color: '#fff', position: 'absolute', top: '0%', height: '100%', width: '50px', left: '0px'}}>
                        <div style={{display: 'flex', width: '100%', height: '100%'}}>
                            <ArrowBack style={{ margin: 'auto', }}/>
                        </div>
                    </div>
                </div>                    
              </div>
              <DialogContent style={{padding: '5px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: '0px', marginBottom: '0px'}}>
                        <div>
                            <img src={this.state.imgDetalhes} style={{margin: '1%', width: '98%'}}></img>
                        </div>
                    </div>
              </DialogContent>
          </Dialog>
        );
    }

    renderDialogInfoObrig = () => {

        const { classes } = this.props;

        const appBarClasses = classNames({
            [classes.appBar]: true,
            [classes.color]: '#000',
            [classes.absolute]: 'absolute',
            [classes.fixed]: 'fixed'
          });

        var versaoTermoAtual = this.state.versaoTermoAtual

        return(
          <Dialog
              open={(this.state.infoObrigOpen && (global.infoObrig != '') && ( (versaoTermoAtual != global.infoObrigVer) || (global.infoObrigVer == 0) ))}
              onClose={() => {
                this.setState({
                    infoObrigOpen: false
                });
              }}
              disableBackdropClick 
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{ container: classes.root, paper: classes.paperFullSize }}
              style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
          >
              <div className={appBarClasses} style={{display: 'flex', flexDirection: 'column', backgroundColor: "#000", marginBottom: '0px'}}>
                <div style={{height: '50px', width: '100%', display: 'flex', position: 'relative'}}>
                  <text style={{fontSize: '18px', fontWeight: 'bold', color: '#fff', margin: 'auto auto auto auto'}}>{global.infoObrigTitulo}</text>
                </div>                    
              </div>
              <DialogContent style={{width: '100%', height: '100%',}}>
                  <div style={{display: 'flex', flexDirection: 'column', marginTop: '0px', marginBottom: '0px'}}>
                      <div>
                        <img src={global.siteurl + "rests/" + global.cod_restaurante + "/imgs/rest/path.php?t=restimg"} style={{width: '80%', margin: '10px 10% 10px 10%'}}></img>
                        <div dangerouslySetInnerHTML={{ __html: global.infoObrig }} />
                      </div>
                  </div>
                    <FormControlLabel
                        control={<AdicionaCheckbox checked={(this.state.regulamentoAceita >= 1) ? true : false} value={"1"} onChange={this.handleChangeRegulamentoAceito} name="checkedG" />}
                        label={"Concordo com os termos acima"} style={{color: corCheckBoxAdiciona, width: '100%',}}
                    />
                    <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (this.state.regulamentoAceita){

                                    //global.infoObrigVer

                                    localStorage.setItem(global.cod_restaurante + "versaoTermosVMX", global.infoObrigVer);
                                    this.setState({
                                        infoObrigOpen: false
                                    });
                                }                                
                            }}
                            style={{backgroundColor: (this.state.regulamentoAceita) ? '#3496eb' : '#ccc'}}
                        >
                        { (this.state.regulamentoAceita) ? 'Continuar' : 'Concorde para prosseguir'}
                    </Button>
              </DialogContent>
          </Dialog>
        );
    }

    renderBanners = () => {

        var items = this.state.bannersList;

        return items.map( (value, index) => {
            return (
                <div onClick={() => value.callback()}><img style={{width: '100%', height: "100%", objectFit: 'contain'}} src={value.URL} /></div>
            );
        });

        
        //<div><img style={{width: '100%', height: "100%", objectFit: 'contain'}} src={global.siteurl + "rests/" + 8 + "/imgs/rest/path.php?t=logo"} /></div>
        //<div><img style={{width: '100%', height: "100%", objectFit: 'contain'}} src={global.siteurl + "rests/" + 12 + "/imgs/rest/path.php?t=logo"} /></div>
    }

    handleCloseAlerta = () => {
        this.setState({
            alertaNewSabor : false,
            alertaNewSaborPromo : false,
            alertaAlePromo: false,
            alertaExitApp: false,
            produtoPromo : [],
            pickProduto  : [],
            pickGruProd  : [],
        });
    }

    renderFooter = () => {
        
        if (global.pedMesa !== true){
            return (
                <Footer ref={this.anchorRef}  qtdeTiposProds={this.state.carrinhoCompra.length} whiteFont/>
            );
        }
        

    }

    renderVersion = () => {
        return (
            <div style={{fontSize: '11px', color: '#555', width: '100%', textAlign: 'center'}}>V 1.0.28</div>
        );
    }

    renderPoliticaPrivacidade = () => {
        
        const {classes} = this.props;

        const appBarClasses = classNames(classes);

        return(
            <Dialog
                open={this.state.politPrivOpen}
                onClose={() => { this.setState({politPrivOpen : false}) }}
                disableBackdropClick 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ container: classes.root, paper: classes.paper }}
                style={{maxWidth: '720px', margin: '0 auto 0 auto', WebkitOverflowScrolling: 'touch'}}
            >
                <AppBar className={appBarClasses} style={{display: 'flex', flexDirection: 'column', backgroundColor: "#000", marginBottom: '0px', position: 'absolute'}}>
                  <div style={{height: '50px', width: '100%', display: 'flex', position: 'relative'}}>
                    <text style={{fontSize: '18px', fontWeight: 'bold', color: '#fff', margin: 'auto auto auto auto'}}>{"Informações"}</text>
                    <div onClick={() => { this.setState({politPrivOpen : false}) }} style={{fontSize: '24px', fontWeight: '400', color: '#fff', position: 'absolute', top: '0%', height: '100%', width: '50px', left: '0px'}}>
                      <div style={{display: 'flex', width: '100%', height: '100%'}}>
                        <ArrowBack style={{ margin: 'auto', }}/>
                      </div>
                    </div>
                  </div>                
                </AppBar>
                <DialogContent style={{width: '100%', height: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: '30px', marginBottom: '0px'}}>
                        <div>
                          <div style={{width: '100%', display: 'flex'}}>
                            <text style={{paddingBottom: '9px', paddingTop: '18px', margin: 'auto', marginTop: '12px', marginBottom: '15px', fontSize: '20px', fontWeight: '410', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', color: '#0380fc' }}>{this.state.politPrivTitulo}</text>
                          </div>
                          <div dangerouslySetInnerHTML={{ __html:  this.state.politPriv}} />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.myBody}>
                <div className={classes.container}>
                    <Card>
                        <div className={classes.form}>
                            <CardHeader color="primary" className={classes.cardHeader} style={{display: 'flex', marginTop: '0px'}}>
                                <img ref={(ref) => {this.cabecalho = ref}}  src={global.siteurl + "rests/" + global.cod_restaurante + "/imgs/rest/path.php?t=logo"} style={{width: '100%', height: "100%", objectFit: 'contain'}}></img>
                                {/*<AutoplaySlider 
                                    play={true}
                                    onFirstMount={(e) => this.changeCorSlider(e, "#fff")}  
                                    onTransitionEnd={(e) => this.changeCorSlider(e, "#fff")}  
                                    interval={6000}
                                    cancelOnInteraction={false} // should stop playing on user interaction
                                    style={{}}>
                                        {this.renderBanners()}
                                </AutoplaySlider>*/}
                            </CardHeader>
                            <CardBody>
                            <Header context={this} searchValue={(this.state.pesquisaTxt)} searchClick={(e) => this.abrePesquisa(e)} searchFunc={(e) => this.pesquisaLista(e)} leftLinks={
                                <div ref={(ref) => this.myMenu = ref} style={{ whiteSpace: 'nowrap', overflowX: 'scroll'}}>
                                    <div style={{width: 'max-content', whiteSpace: 'nowrap', flexDirection: 'column', marginTop: '12px', marginBottom: '12px', }} className={""}>
                                        {this.renderMenu(this.state.grupos_prods)}
                                    </div>                                    
                                </div>
                            } />
                                <List ref={(ref) => this.cardBody = ref} className={classes.root}>
                                    <List className={classes.root}>{this.renderLista(this.state.grupos_prods)}</List>   
                                </List>
                                {this.renderVersion()}
                            </CardBody>
                        </div>                
                    </Card>
                </div>
                {this.renderDialogInfoObrig()}
                {this.renderDialogAddProd()}
                {this.renderDialogImage()}
                {this.renderDialogPromoAle()}

                <Confirmacao 
                    showAlert={this.state.alertaExitApp}
                    onClose={this.handleCloseAlerta}
                    callbackYes={() => {

                        CapacitorApp.exitApp();
                        
                    }} 
                    title={'Atenção'}
                    msg={'Deseja mesmo sair do App?'}
                    callbackNo={() => {

                        this.handleCloseAlerta();                        
                        
                    }}
                />

                <Confirmacao 
                    showAlert={this.state.alertaNewSaborPromo}
                    onClose={this.handleCloseAlerta}
                    callbackYes={() => {

                        var prod = this.state.produtoPromo;
                        for (var i = 0; i < this.state.grupos_prods.length; i++){
                            if (this.state.grupos_prods[i].codigo == prod.gruProdsCod){
                                global.ProductObj.setQtdeSabores(2, this.state.grupos_prods[i], prod.gruProdsCod, prod.gruProdsNome);    
                                break;                
                            }
                        }

                        global.ProductObj.setSabor(prod);

                        

                        this.setState({
                            alertaAddProd : true
                        }, () => {
                            this.handleCloseAlerta();
                        });
                        
                    }} 
                    title={'Atenção'}
                    msgDestaque={this.state.produtoPromo.nomeProduto}
                    msg={'Deseja dois sabores nesse produto?'}
                    callbackNo={() => {

                        var prod = this.state.produtoPromo;
                        for (var i = 0; i < this.state.grupos_prods.length; i++){
                            if (this.state.grupos_prods[i].codigo == prod.gruProdsCod){
                                global.ProductObj.setQtdeSabores(1, this.state.grupos_prods[i], prod.gruProdsCod, prod.gruProdsNome);    
                                break;                
                            }
                        }

                        global.ProductObj.setSabor(prod);

                        this.setState({
                            alertaAddProd : true
                        }, () => {
                            this.handleCloseAlerta();
                        });
                        
                        
                    }}
                    />

                <Confirmacao 
                    showAlert={this.state.alertaNewSabor}
                    onClose={this.handleCloseAlerta}
                    callbackYes={() => {

                        var prod = this.state.pickProduto;
                        var gruProd =  this.state.pickGruProd;

                        global.ProductObj.setQtdeSabores(2, gruProd, gruProd.codigo, gruProd.nome);    
                        global.ProductObj.setSabor(prod);                        

                        this.setState({
                            alertaAddProd : true
                        }, () => {
                            this.handleCloseAlerta();
                        });
                        
                    }} 
                    title={'Atenção'}
                    msgDestaque={this.state.produtoPromo.nomeProduto}
                    msg={'Deseja dois sabores nesse produto?'}
                    callbackNo={() => {

                        var prod = this.state.pickProduto;
                        var gruProd =  this.state.pickGruProd;

                        global.ProductObj.setQtdeSabores(1, gruProd, gruProd.codigo, gruProd.nome);    
                        global.ProductObj.setSabor(prod);

                        this.setState({
                            alertaAddProd : true
                        }, () => {
                            this.handleCloseAlerta();
                        });
                        
                        
                    }}
                    />
                {this.renderFooter()}
                {this.renderPoliticaPrivacidade()}
            </div>
        );
    }
}

export default withStyles(PrincipalPage, styles); ;
